/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchAttendanceInit } from '../AttendanceActions'
import { getAttendanceListData } from '../AttendanceReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'
import Payment from './PaymentDetails'
import ArchiveOrcament from './ArchiveOrcament'
import ModalShowVisits from './ModalShowVisits'

class Attendance extends Component {
  componentDidMount() {
    // const { fetchAttendanceInit } = this.props
    // fetchAttendanceInit()
  }

  render() {
    const { classes, permissions, items } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={1}
          alignItems="center"
        >
          <Grid item xs>
            <Typography variant="h4" color="secondary">
              Atendimentos
            </Typography>
          </Grid>
          <Grid item>
            <Filter classes={classes} />
          </Grid>
          <Grid item>
            {renderRoute(['create-attendances'], permissions) && (
              <Button
                color="primary"
                variant="contained"
                component={Link}
                to="/attendance/new"
              >
                <AddCircleOutlineIcon className={classes.btnIcon} /> Cadastrar
              </Button>
            )}
          </Grid>
        </Grid>

        {renderRoute(['create-attendances'], permissions) && (
          <Route path="/attendance/new" component={New} />
        )}
        {renderRoute(['update-attendances'], permissions) && (
          <Route path="/attendance/:id/edit" component={Edit} />
        )}
        {renderRoute(['create-billing-client'], permissions) && (
          <Route path="/attendance/:id/payment" component={Payment} />
        )}

        {renderRoute(['read-attendances'], permissions) && (
          <Route path="/attendance/visits/:id" component={ModalShowVisits} />
        )}

        {renderRoute(['archive-attendances'], permissions) && (
          <Route
            path="/attendance/archive-orcament/:id"
            component={ArchiveOrcament}
          />
        )}

        <List {...this.props} items={items} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getAttendanceListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAttendanceInit,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Attendance))
