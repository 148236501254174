import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core'
import React from 'react'
import colors from '../../../../assets/colors'
import {
  mdiCalendar,
  mdiCalendarBlank,
  mdiCurrencyUsd,
  mdiSync,
  mdiThumbUp,
} from '@mdi/js'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'
import { captureLead } from '../PanelActions'
import Icon from '@mdi/react'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  cardLead: {
    height: 'max-content',
    backgroundColor: colors.lightGray,
    borderRadius: '5px',
    padding: '20px',
    position: 'relative',
    animation: '$fadeIn .4s ease-in-out',
    color: colors.gray,
    cursor: 'pointer',
    transition: 'all .4s ease-in-out',
    opacity: 0.7,

    display: 'flex',
    flexDirection: 'column',
    gap: '12px',

    '&:hover': {
      opacity: 1,
    },
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'start',
    gap: '12px',
    position: 'absolute',
    paddingTop: '4px',
    top: '-24px',
  },
  cardTitleIcon: {
    borderRadius: '50%',
    color: '#fff',
  },
  cardTitleTypography: {
    fontWeight: 'bold',
    color: colors.gray,
  },
  cardBody: {
    marginTop: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  cardBodyOsInfo: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  cardBodyDateSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardBodyOsInfoBtn: {
    backgroundColor: colors.secondary,
    borderRadius: '5px',
    padding: '0 15px 0 15px',
    color: '#fff',
    fontSize: '12px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      opacity: 0.7,
      backgroundColor: colors.secondary,
    },
  },
  cardFooter: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
})

const CardLead = ({
  triggerType = '',
  clientName = '',
  company = '',
  schedulingDate = '',
  reason = '',
  state = '',
  saller = '',
  leadId = '',
}) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const history = useHistory()

  const handleCaptureLead = async () => {
    const resp = await confirm({
      description: `Deseja capturar o lead: ${leadId}?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    })
      .then(() => {
        dispatch(captureLead(leadId))
      })
      .then(() => {
        return true
      })

    if (resp) {
      history.push(`/crm/panel/lead/${leadId}`)
    }
  }

  const handleRenderCardTitle = () => {
    switch (triggerType) {
      case 10:
        return (
          <>
            <Box className={classes.cardTitleIcon}>
              <Icon
                path={mdiCurrencyUsd}
                size={1.4}
                style={{ backgroundColor: colors.error, borderRadius: '50%' }}
              />
            </Box>
            <Typography className={classes.cardTitleTypography}>
              OS não fechada
            </Typography>
          </>
        )
      case 20:
        return (
          <>
            <Box className={classes.cardTitleIcon}>
              <Icon
                path={mdiSync}
                size={1.4}
                style={{
                  backgroundColor: colors.secondary,
                  borderRadius: '50%',
                }}
              />
            </Box>
            <Typography className={classes.cardTitleTypography}>
              Recorrência
            </Typography>
          </>
        )
      default:
        break
    }
  }

  return (
    <Grid item sm={6} md={4} xs={12}>
      <Box className={classes.cardLead}>
        <Box className={classes.cardTitle}>{handleRenderCardTitle()}</Box>
        <Box className={classes.cardBody}>
          <Box className={classes.cardBodyOsInfo}>
            <Typography style={{ fontWeight: 'bold' }}>Cliente:</Typography>
            <Typography>{clientName}</Typography>
          </Box>
          <Box className={classes.cardBodyOsInfo}>
            <Typography style={{ fontWeight: 'bold' }}>Lavanderia:</Typography>
            <Typography>{company}</Typography>
          </Box>
          {state == 20 && (
            <Box className={classes.cardBodyOsInfo}>
              <Typography style={{ fontWeight: 'bold' }}>Vendedor:</Typography>
              <Typography>{saller}</Typography>
            </Box>
          )}
        </Box>

        <Box className={classes.cardBodyDateSection}>
          <Box className={classes.cardBodyOsInfo}>
            <Icon path={mdiCalendarBlank} size={1} color={colors.primary} />
            <Typography>{schedulingDate}</Typography>
          </Box>

          <Box className={classes.cardBodyOsInfo}>
            {state == 10 && (
              <Button
                type="button"
                className={classes.cardBodyOsInfoBtn}
                onClick={handleCaptureLead}
              >
                Capturar
              </Button>
            )}

            {state == 20 && (
              <Button
                type="button"
                className={classes.cardBodyOsInfoBtn}
                component={Link}
                to={`/crm/panel/lead/${leadId}`}
              >
                Trabalhar
              </Button>
            )}
          </Box>
        </Box>

        {triggerType == 10 && (
          <Box className={classes.cardFooter}>
            <Box className={classes.cardBodyOsInfo}>
              <Typography style={{ fontWeight: 'bold' }}>Motivo:</Typography>
              <Typography>{reason}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export default CardLead
