import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { fetchLastestLead, fetchLead, updateLead } from '../../PanelActions'
import { useDispatch, useSelector } from 'react-redux'
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  TextField,
  Toolbar,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core'
import colors from '../../../../../assets/colors'
import ServiceItemsTable from './ServiceItemsTable'
import ObservationsTable from './ObservationsTable'
import Icon from '@mdi/react'
import { mdiAlert, mdiPlus } from '@mdi/js'
import filterStylesComponents from '../../../../../assets/filterComponentsStyles'
import ModalCreateDescription from './ModalCreateDescription'
import ModalTransferOs from './ModalTransferOs'
import ModalCloseLead from './ModalCloseLead'
import ModalNewOrcament from './ModalNewOrcament'
import CloseIcon from '@material-ui/icons/Close'
import styles from '../../../../../resources/theme/users'
import ReactInputMask from 'react-input-mask'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    animation: '$fadeIn .4s ease-in-out',
  },
  box: {
    padding: '10px 0px 20px 10px',
    width: '100%',
    borderBottom: `1px dashed ${colors.graylight}`,
    color: colors.gray,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  cardSubTitles: {
    color: colors.secondary,
    fontSize: '16px',
    lineHeight: '16px',
    fontWeight: 'bold',
    margin: '10px 0',
  },
  footer: {
    marginTop: '20px',
  },
  latestLead: {
    display: 'flex',
    backgroundColor: colors.bgError,
    padding: '4px 20px',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.error,
    borderRadius: '12px',
  },
})

const LeadForm = props => {
  const lead = useSelector(state => state.crmPanel.editingLead)

  const { classes } = props

  const history = useHistory()
  const leadFormClasses = useStyles()
  const filterStyles = filterStylesComponents()
  const dispatch = useDispatch()

  const [openObservations, setOpenObservations] = useState(false)
  const [openTransferOs, setOpenTransferOs] = useState(false)
  const [openCloseLead, setOpenCloseLead] = useState(false)
  const [openNewOrcament, setOpenNewOrcament] = useState(false)
  const [latestLead, setLatestLead] = useState(null)

  const handleOpenObservations = () => {
    setOpenObservations(!openObservations)
  }

  const handleOpenTransferOs = () => {
    setOpenTransferOs(!openTransferOs)
  }

  const handleOpenCloseLead = () => {
    setOpenCloseLead(!openCloseLead)
  }

  const handleOpenNewOrcament = () => {
    setOpenNewOrcament(!openNewOrcament)
  }

  const { id } = props.match.params

  const handleClose = () => {
    history.push('/crm/panel')
  }

  const searchLatestLead = async () => {
    try {
      const resp = await dispatch(fetchLastestLead(id))

      if (resp?.lead) {
        setLatestLead(resp?.lead)
      }
    } catch (e) {
      //
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const formData = e.currentTarget
    const data = new FormData(formData)
    const entries = data.entries()
    const values = Object.fromEntries(entries)

    try {
      const resp = await dispatch(updateLead(id, values))

      if (resp) {
        handleClose()
      }
    } catch (e) {
      //
    }
  }

  useEffect(() => {
    searchLatestLead()
    dispatch(fetchLead(id))
  }, [id])

  return (
    <Dialog
      fullScreen
      open
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" color="inherit">
                {`OS: ${lead?.id} | ${
                  lead?.trigger_type == 20 ? 'RECORRÊNCIA' : 'OS EM ABERTO'
                }`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box clasName={leadFormClasses.container}>
            <Box className={leadFormClasses.box}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography
                        style={{ fontWeight: 'bold', color: colors.error }}
                      >
                        Lead mais recente encontrado:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: colors.error }}>
                        {latestLead?.id}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography
                        style={{ fontWeight: 'bold', color: colors.error }}
                      >
                        Criado por:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: colors.error }}>
                        {latestLead?.attendant}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography
                        style={{ fontWeight: 'bold', color: colors.error }}
                      >
                        Data de criação:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography style={{ color: colors.error }}>
                        {latestLead?.created_at}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Box className={leadFormClasses.latestLead}>
                        <Icon path={mdiAlert} size={1} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            <Box className={leadFormClasses.box}>
              <Grid container alignItems="center">
                <Grid item xs={3}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Capturado por:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{lead?.user_capture}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Estado Atual:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{lead?.state}</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Data de agendamento:
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        type="date"
                        defaultValue={lead?.scheduling_date}
                        name="scheduling_date_crm"
                        key={`scheduling_date_crm-${lead?.scheduling_date}`}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Grid container spacing={1} justifyContent="flex-end">
                    <Grid item>
                      <Button
                        className={filterStyles.btnAdd}
                        style={{ textTransform: 'none' }}
                        onClick={handleOpenTransferOs}
                      >
                        Transferir OS
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box className={leadFormClasses.box}>
              <Typography className={leadFormClasses.cardSubTitles}>
                Informações do cliente:
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={2}>
                  <TextField
                    label="Nome"
                    fullWidth
                    disabled
                    value={lead?.client?.name}
                    key={`input-name-${lead?.client?.name}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Rua"
                    fullWidth
                    disabled
                    value={lead?.client?.street}
                    key={`input-street-${lead?.client?.street}`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Bairro"
                    fullWidth
                    disabled
                    value={lead?.client?.neighborhood}
                    key={`input-neighborhood-${lead?.client?.neighborhood}`}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Cidade"
                    fullWidth
                    disabled
                    value={lead?.client?.city}
                    key={`input-city-${lead?.client?.city}`}
                  />
                </Grid>
                <Grid item xs={2}>
                  {/* <TextField
                    label="Telefone"
                    fullWidth
                    disabled
                    value={lead?.client?.phone}
                    key={`input-phone-${lead?.client?.phone}`}
                  /> */}
                  <ReactInputMask
                    mask={
                      lead?.client?.phone.replace(/[^\d]/g, '').length <= 10
                        ? '(99) 9999-9999?'
                        : '(99) 99999-9999'
                    }
                    maskChar=""
                    disabled
                    value={lead?.client?.phone}
                    key={`input-phone-${lead?.client?.phone}`}
                  >
                    {inputProps => (
                      <TextField {...inputProps} label="Telefone" fullWidth />
                    )}
                  </ReactInputMask>
                </Grid>
              </Grid>
            </Box>
            <Box className={leadFormClasses.box}>
              <Typography className={leadFormClasses.cardSubTitles}>
                Itens da OS:
              </Typography>

              <ServiceItemsTable items={lead?.service_items} />
            </Box>
            <Box className={leadFormClasses.box}>
              <Typography className={leadFormClasses.cardSubTitles}>
                Observações:
              </Typography>

              <ObservationsTable items={lead?.observations} />

              <Grid container justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    style={{
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                      fontWeight: 'bold',
                      padding: '4px 12px',
                      marginTop: '4px',
                    }}
                    type="button"
                    onClick={handleOpenObservations}
                  >
                    <Icon
                      path={mdiPlus}
                      size={1}
                      style={{
                        backgroundColor: colors.secondary,
                        color: '#FFF',
                        borderRadius: '50%',
                        marginRight: '4px',
                      }}
                    />
                    Adicionar observação
                  </IconButton>
                </Grid>
              </Grid>
            </Box>

            <Box className={leadFormClasses.footer}>
              <Grid container>
                <Grid item xs={6}>
                  <Grid container spacing={2} justifyContent="flex-start">
                    <Grid item>
                      <Button
                        className={filterStyles.btnAdd}
                        style={{
                          backgroundColor: colors.error,
                          textTransform: 'none',
                        }}
                        type="button"
                        onClick={handleOpenCloseLead}
                      >
                        Bloquear cliente
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        className={filterStyles.btnAdd}
                        style={{ textTransform: 'none' }}
                        type="button"
                        onClick={handleOpenNewOrcament}
                      >
                        Abrir novo orçamento
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      className={filterStyles.btnAdd}
                      style={{ textTransform: 'none' }}
                      type="submit"
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </form>
      </DialogContent>

      <ModalCreateDescription
        open={openObservations}
        handleOpen={handleOpenObservations}
        leadId={id}
      />

      <ModalTransferOs
        open={openTransferOs}
        handleOpen={handleOpenTransferOs}
        leadId={id}
      />

      <ModalCloseLead
        open={openCloseLead}
        handleOpen={handleOpenCloseLead}
        leadId={id}
      />

      <ModalNewOrcament
        open={openNewOrcament}
        handleOpen={handleOpenNewOrcament}
        leadId={id}
      />
    </Dialog>
  )
}

export default withStyles(styles)(LeadForm)
