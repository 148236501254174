/* eslint-disable prettier/prettier */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { IconButton, Snackbar, withStyles } from '@material-ui/core'
import { GrDocumentUser } from 'react-icons/gr'
import { useConfirm } from 'material-ui-confirm'
import axios from 'axios'
import config from '../../../config'
import { Alert } from '@material-ui/lab'
// import ModalShowVisits from './ModalShowVisits'
import Icon from '@mdi/react'
import { mdiAccountSwitch } from '@mdi/js'
import { useDispatch, useSelector } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import { renderRoute } from '../../../util/utils'
import { Route, useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const styles = theme => ({
  container: {
    margin: theme.spacing.unit * 2,
  },
})

const PopoverPopupState = ({ serviceId, classes }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const searchDataVisits = async () => {
    try {
      const resp = await axios.get(
        `${config.API_URL}/all-visits?id=${serviceId}`
      )
      if (resp) {
        history.push(`/attendance/visits/${serviceId}`)
      }
    } catch (e) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: e?.response?.data?.message,
        },
      })
    }
  }

  return (
    <IconButton
      onClick={() => searchDataVisits()}
      // className={classes.menuItemLi}
    >
      <Icon
        path={mdiAccountSwitch}
        size={1}
        className={classes.menuItemLiIcon}
        title={'Visitas'}
      />
    </IconButton>
  )
}

export default withStyles(styles)(PopoverPopupState)
