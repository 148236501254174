import { FETCH_LEAD, FETCH_LEADS, REFETCH_LEADS } from './PanelActions'

const INITIAL_STATE = {
  leads: [],
  meta: {},
  triggers: [],
  editingLead: {},
  filters: {},
  attendants: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LEADS.SUCCESS:
      return {
        ...state,
        leads: action.payload.data.data,
        meta: action.payload.data.meta,
        triggers: action.payload.data.triggers,
        filters: action.payload.filters,
        attendants: action.payload.data?.attendants,
      }
    case REFETCH_LEADS.SUCCESS:
      return {
        ...state,
        meta: action.payload.data.meta,
        filters: action.payload.filters,
      }
    case FETCH_LEAD.SUCCESS:
      return {
        ...state,
        editingLead: action.payload.data?.data,
        attendants: action.payload.data?.attendants,
      }
    default:
      return state
  }
}
