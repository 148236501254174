/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FaFileDownload } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
} from '@material-ui/core'
import config from '../../../config'
import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import colors from '../../../assets/colors'
import { mdiFileDownloadOutline, mdiReceiptTextOutline } from '@mdi/js'
import Icon from '@mdi/react'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '.5rem',
    width: '50%',
    height: '70%',
    border: `2px solid ${colors.secondary}`,
  },
  title: {
    fontSize: '1.5rem',
    fontFamily: 'sans-serif',
    textAlign: 'center',
    color: colors.primary,
  },
  tableContainer: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableHeadCell: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
}))

const ModalShowVisits = props => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const [dataVisits, setDataVisits] = useState([])

  const { id } = props.match.params

  const searchDataVisits = async () => {
    try {
      const resp = await axios.get(`${config.API_URL}/all-visits?id=${id}`)

      if (resp) {
        setDataVisits(resp?.data?.data || [])
      }
    } catch (e) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: e?.response?.data?.message,
        },
      })
    }
  }

  const handleDownloadVisita = async (id, data) => {
    try {
      const response = await axios.get(
        `${config.API_URL}/download-visita?id=${id}&serviceId=${id}`,
        {
          responseType: 'blob',
        }
      )

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)

      const downloadLink = document.createElement('a')
      downloadLink.href = pdfUrl
      downloadLink.setAttribute('download', `visita_${id}_${data}`)
      downloadLink.style.display = 'none'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    } catch (e) {
      console.error('Erro ao fazer a solicitação: ', e)
    }
  }

  const handleClose = () => {
    history.push('/attendance')
  }

  useEffect(() => {
    if (id) {
      searchDataVisits()
    }
  }, [id])

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={classes.paper}>
          <h2 id="transition-modal-title" className={classes.title}>
            Visitas relacionadas ao atendimento
          </h2>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell}>ID</TableCell>
                <TableCell align="center" className={classes.tableHeadCell}>
                  Data
                </TableCell>
                <TableCell align="right" className={classes.tableHeadCell}>
                  Ação
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataVisits?.map(row => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell align="center">{row.created_at}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() =>
                        handleDownloadVisita(row.id, row.created_at)
                      }
                    >
                      <Icon
                        path={mdiFileDownloadOutline}
                        size={1}
                        title="Download"
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </>
  )
}

export default ModalShowVisits
