import {
  Box,
  Card,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'
// import CardLead from './CardLead'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLeads, refetchLeads } from '../PanelActions'
import { useConfirm } from 'material-ui-confirm'
import { renderRoute, renderRouteRole } from '../../../../util/utils'
// import LeadForm from './lead'
import { Route } from 'react-router-dom/cjs/react-router-dom'
import styles from '../../../../resources/theme/global'
import Filter from './Filter'
import CardLead from './CardLead'
import colors from '../../../../assets/colors'
import LeadForm from './lead'
import useOnScreen from '../../../../hook/useOnScreen'
import Icon from '@mdi/react'
import { mdiLoading } from '@mdi/js'
import { format } from 'date-fns'

const useStyles = makeStyles({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  cardBox: {
    height: '100%',
    padding: '20px 8px 8px 0',
    maxHeight: 'calc(100vh - 230px)',
    marginTop: '16px',
    overflowY: 'auto',
    overflowX: 'hidden',
    animation: '$fadeIn .4s ease-in-out',
    '&::-webkit-scrollbar': {
      width: '3px',
      height: '8px',
      backgroundColor: colors.graylight,
    },
    '&::-webkit-scrollbar-thumb': {
      background: colors.secondary,
    },
  },
})

const CrmPanel = props => {
  const initialLeads = useSelector(state => state.crmPanel.leads)
  const meta = useSelector(state => state.crmPanel.meta)
  const role = useSelector(state => state.auth.role)

  const dispatch = useDispatch()
  const leadClasses = useStyles()

  const { classes } = props

  const [leads, setLeads] = useState()
  const [value, setValue] = useState(0)

  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState({
    page: 1,
    client: '',
    os: '',
    trigger_id: '',
    state: '10',
    date_end: format(new Date(), 'yyyy-MM-dd'),
  })

  const handleRefatchLeads = async () => {
    setLoading(true)

    const data = {
      ...form,
      page: Number(meta?.current_page + 1),
    }

    try {
      const resp = await dispatch(refetchLeads(data))

      if (resp?.data) {
        const { data } = resp

        if (!data?.length) return

        const allLeads = [...leads]
        allLeads.push(...data)

        setLeads(allLeads)
      }

      setLoading(false)
    } catch (e) {
      setLoading(false)

      console.log(e)
    }

    setForm(data)
  }

  useEffect(() => {
    if (form?.page > 1) return

    dispatch(fetchLeads(form))
  }, [form])

  useEffect(() => {
    setForm({
      ...form,
      state: value == 0 ? 10 : 20,
      page: 1,
    })
  }, [value])

  useEffect(() => {
    if (!isVisible) return

    if (!(Number(meta?.to) < Number(meta?.total))) return

    handleRefatchLeads()
  }, [isVisible])

  useEffect(() => {
    setLeads(initialLeads || [])
  }, [initialLeads])

  return (
    <Box
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        maxHeight: 'calc(100vh - 80px)',
      }}
    >
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="h4" color="secondary">
            Painel
          </Typography>
        </Grid>
        <Grid item>
          <Filter form={form} setForm={setForm} classes={classes} role={role} />
        </Grid>
      </Grid>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          flex: 1,
        }}
      >
        <Paper square>
          <Tabs value={value} onChange={(e, newValue) => setValue(newValue)}>
            <Tab label="Entrada" tabIndex={10} color="secondary" />
            <Tab label="Cadenciamento" tabIndex={20} />
          </Tabs>
        </Paper>

        <Grid container spacing={4} className={leadClasses.cardBox}>
          {leads?.map((lead, index) => (
            <CardLead
              key={`crm-lead-${index}`}
              state={form.state}
              triggerType={lead?.trigger_type}
              clientName={lead?.client_name}
              schedulingDate={lead?.scheduling_date}
              company={lead?.company}
              reason={lead?.reason}
              saller={lead?.attendant}
              leadId={lead?.id}
            />
          ))}

          <Grid item xs={12}>
            <div
              ref={ref}
              style={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                color: colors.textSecondary,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {loading && (
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <Icon path={mdiLoading} size={1} spin />
                  <span style={{ marginLeft: '4px' }}>Carregando...</span>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Box>

      {renderRouteRole(
        [
          'administrador',
          'lavanderia',
          'atendente',
          'AtendenteLavanderia',
          'atendente_crm',
        ],
        role
      ) && <Route path="/crm/panel/lead/:id" component={LeadForm} />}
    </Box>
  )
}

export default withStyles(styles)(CrmPanel)
